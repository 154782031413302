<template>
  <div
  class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 mb-5 box p-3 tamano-texto"
  >
    <div
    v-for="(row,k) in getOrdersDomain"
    :key="k"
    >
      <div>({{k}}). {{row.name}} </div>
      <div class="item" :class="row.cssClass" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import ordersDomain from '../../../../../../../domains/ordersDomain'
export default {
  name: 'RequestLegends',
  setup () {
    const getOrdersDomain = computed(() => {
      return ordersDomain
    })
    return {
      ordersDomain,
      getOrdersDomain
    }
  }
}
</script>

<style scoped>
.item{
  height: 15px;
  width: 30px;
  border-radius: 2px;
}
.tamano-texto{
  font-size:12px;
}
</style>
